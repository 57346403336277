import { Container } from "react-bootstrap";

const Footer = () => {

    return (
        <div className="bg-primary text-white py-2">
            <Container>
                <div className="row">
                    <div className="col"> &copy; 2021 Traicert Technologies</div>
                    <div className="col"></div>
                </div>
            </Container>
        </div>
    )
}

export default Footer;
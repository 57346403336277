import { Link } from "react-router-dom";
import { Navbar, Container, Nav } from 'react-bootstrap'

const Header = () => {
    return (
        <Navbar bg="primary" variant="dark" expand="lg" className="bg-primary p-0">
        <Container>
          <Navbar.Brand as={Link} to="/">Traicert</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav>
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <Nav.Link as={Link} to="/about">about</Nav.Link>
              <Nav.Link as={Link} to="/trainings">Trainings</Nav.Link>
              <Nav.Link as={Link} to="/certifications">Certifications</Nav.Link>
              <Nav.Link as={Link} to="/placements">Placements</Nav.Link>
              <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
}

export default Header
import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Interceptors from "./components/interceptors/Interceptors";

const Home = lazy(() => import(/*webpackChunkName: 'home'*/"./components/home/Home"));
const About = lazy(() => import(/*webpackChunkName: 'about'*/"./components/about/About"));
const Placements = lazy(() => import(/*webpackChunkName: 'placements'*/"./components/placements/Placements"));
const Trainings = lazy(() => import(/*webpackChunkName: 'trainings'*/"./components/trainings/Trainings"));
const Certifications = lazy(() => import(/*webpackChunkName: 'certifications'*/"./components/certifications/Certifications"));
const Contact = lazy(() => import(/*webpackChunkName: 'contact'*/"./components/contact/Contact"));
const Posts = lazy(() => import(/*webpackChunkName: 'posts'*/"./components/posts/Posts"));
const PostsDetails = lazy(() => import(/*webpackChunkName: 'postsDetails'*/"./components/posts-details/PostsDetails"));
const NotFound = lazy(() => import(/*webpackChunkName: 'pageNotFound'*/"./components/notFound/NotFound"));

const App = () => {
  return (
    // <Router basename="http://traicert.in/"> for localhost
    <Router>
      <Header />
      <Suspense fallback={<Interceptors loading="true" />}>
        <Interceptors />
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/placements' element={<Placements />} />
          <Route path='/trainings' element={<Trainings />} />
          <Route path='/certifications' element={<Certifications />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/posts' element={<Posts />} />
          <Route exact path='/posts/:url' element={<PostsDetails />} />
          <Route exact path='*' element={<NotFound />} />
        </Routes>
      </Suspense>
      <Footer />
    </Router>
  )
}

export default App
import { useState, useEffect } from "react";
import axios from "axios";
import './Interceptors.scss';

const Interceptors = (props) => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
  }, [props])

  axios.interceptors.request.use((request) => {
    setLoading(true);
    return request;
  })

  axios.interceptors.response.use((response) => {
    setLoading(false);
    return response;
  }, (error) => {
    setLoading(false);
    return error;
  })

  return ( (props.loading || loading) && (
    <div className="spinner-container">
      <div className="spinner">
        <svg viewBox="22 22 44 44">
          <circle cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6">
          </circle>
        </svg>
      </div>
    </div>
  )
  )
}

export default Interceptors;